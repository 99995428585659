/* Fullscreen overlay for popup */
.create-note-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Popup content styling */
.popup-content {
  position: relative;
  padding: 25px;
  margin: 0 10px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  min-width: 350px;
  height: 300px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--off-white);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

/* Popup heading */
.popup-content h2 {
  margin-bottom: 20px;
  color: var(--dark-green);
  font-size: 24px;
}

.settings-container {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}



.language-section {
  text-align: left;
  width: 100%;
}

.language-section label,
.options-label {
  display: block;
  margin-bottom: 5px;
  color: var(--dark-green);
  text-align: left;
}

/* Dropdown for selecting language */
.language-select {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid var(--light-green);
  background-color: var(--pure-white);
}

.settings-options {
  width: 100%;
}

.checkbox-grid {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 5px;
}

.checkbox-group {
  display: flex;
  gap: 8px;
}

.checkbox-group input[type="checkbox"] {
  margin: 0;
  width: 16px;
  height: 16px;
}

.checkbox-group label {
  font-size: 0.9rem;
  color: var(--dark-green);
}

/* Responsive labels for desktop/mobile */
.mobile-label {
  display: none;
}

.desktop-label {
  display: inline;
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .mobile-label {
    display: inline;
  }
  
  .desktop-label {
    display: none;
  }
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-top: 25px;
}

.toolbar-button.new-note-button {
  flex: 1;
  background-color: var(--medium-green);
  color: var(--pure-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
}


.close-button {
  background: none;
  padding: 0px 10px;
  color: var(--dark-green);
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  font-weight: 500;
  height: 30px;
  border-radius: 4px;
}

.close-button:hover {
  color: white;
  background-color: var(--dark-green);
}

/* Close button container */
.close-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Close button */
.close-btn {
  border: none;
  cursor: pointer;
  background: none;
  font-size: 1.5rem;
  color: var(--dark-green);
  padding: 5px;
  line-height: 1;
}


/* Start recording button */
.start-recording-btn {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--off-white);
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
  background-color: var(--medium-green);
  transition: background-color 0.3s ease;
}


.toolbar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar-button.new-note-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}
/* Dropdown for selecting language */
.language-select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid var(--light-green);
}

.checkbox-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.checkbox-group {
  display: flex;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 5px;
}

.recording-container {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Individual sound wave bars */
.sound-bar {
  width: 10px;
  height: 100%;
  margin: 0 5px;
  border-radius: 5px;
  transform: scaleY(0.2);
  background-color: var(--medium-green);
  animation: soundWave 2s ease-in-out infinite;
}

.sound-bar.paused {
  animation-play-state: paused;
}

.sound-bar.standby {
  transform: scaleY(0.2); 
  animation: none;
}

/* Animation for sound wave bars */
@keyframes soundWave {
  0%, 100% {
    transform: scaleY(0.2);
  }
  50% {
    transform: scaleY(0.5);
  }
}

.recording-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.recording-controls button {
  color: var(--pure-white);
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--medium-green);
  transition: background-color 0.3s ease;
}

.recording-controls button:hover {
  background-color: var(--dark-green);
}

.dictation-textarea {
  width: 100%;
  height: 300px;
  padding: 15px;
  font-size: 16px;
  line-height: 1.5;
  border: 2px solid var(--light-green);
  border-radius: 8px;
  background-color: var(--pure-white);
  color: var(--dark-green);
  resize: vertical;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.dictation-textarea:focus {
  outline: none;
  border-color: var(--medium-green);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* New styles for Preparing Transcript state */
.preparing-transcript {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
}

.preparing-transcript h2 {
  margin: 0;
  color: var(--dark-green);
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid var(--light-green);
  border-top: 4px solid var(--medium-green);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Confirmation popup styles */
.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 300px;
  width: 90%;
  text-align: center;
}

.confirmation-content h3 {
  margin-top: 0;
  color: #333;
}

.confirmation-content p {
  margin: 15px 0;
  color: #666;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirmation-buttons button {
  padding: 8px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  min-width: 100px;
}

.cancel-btn {
  background-color: #f0f0f0;
  color: #333;
}

.confirm-btn {
  background-color: #dc3545;
  color: white;
}

.cancel-btn:hover {
  background-color: #e0e0e0;
}

.confirm-btn:hover {
  background-color: #c82333;
}

/* Recording state styles */
.recording-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recording-container {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.recording-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.recording-controls button {
  padding: 10px 20px;
  background-color: var(--medium-green);
  color: var(--pure-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recording-controls button:hover {
  background-color: var(--dark-green);
}

.recording-start-button {
  flex: 1;
  width: auto;
  background-color: var(--medium-green);
  color: var(--pure-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
  padding: 10px 20px;
}

.recording-start-button:hover {
  background-color: var(--dark-green);
}