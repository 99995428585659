/* Base Container */
.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header Styles */
.landing-page__header {
  padding: 20px;
}

.landing-page__header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90vw;
  margin: 0 auto;
}

.landing-page__logo {
  height: 40px;
}

.landing-page__nav {
  display: flex;
  gap: 20px;
}

.landing-page__nav-item {
  cursor: pointer;
}

/* Button Styles */
.landing-page__get-started-button,
.landing-page__action-button,
.landing-page__cta-button {
  padding: 10px 20px;
  background-color: #075715;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.landing-page__get-started-button:hover,
.landing-page__action-button:hover,
.landing-page__cta-button:hover {
  background-color: #053d0f;
}

/* Hero Section */
.landing-page__hero-container {
  position: relative;
  margin-bottom: 20px; /* Create overlap with testimonials */
  z-index: 1;
}

.landing-page__hero-background {
  position: absolute;
  top: 50px;
  left: 1px;
  width: 100vw;
  height: 600px;
  background: url('../../assets/Hero.svg') no-repeat center / cover;
  z-index: 0;
  animation: fadeIn 2.2s ease-out 0.4s forwards;
  opacity: 0;
}

.landing-page__hero-reflection {
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 100%;
  height: 150px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f9fbfa);
  z-index: 2;
}

.landing-page__main {
  padding: 50px 20px;
  text-align: center;
  min-height: 600px;
  position: relative;
}

.landing-page__main-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

/* Entrance Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.landing-page__title {
  font-size: clamp(2.5rem, 5vw, 4rem);
  margin: -60px auto 24px;
  line-height: 1.2;
  font-weight: 700;
  color: #1a1a1a;
  animation: fadeInUp 0.8s ease-out forwards;
}

.landing-page__subtitle {
  font-size: clamp(1.2rem, 2vw, 1.25rem);
  font-weight: 500;
  color: #666;
  margin: 0 auto 32px;
  max-width: 600px;
  line-height: 1.6;
  animation: fadeInUp 0.8s ease-out 0.2s forwards;
  opacity: 0;
}

/* Action Section */
.landing-page__action {
  position: relative;
  z-index: 1;
}

.landing-page__action-button {
  animation: fadeIn 0.6s ease-out 0.4s forwards;
  opacity: 0;
}

/* Features Section */
.landing-page__features {
  padding: 80px 20px;
  background-color: none;
}

.landing-page__feature {
  display: flex;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto 50px;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
}

.landing-page__feature:nth-child(2) {
  animation-delay: 0.2s;
}

.landing-page__feature:nth-child(3) {
  animation-delay: 0.4s;
}

.landing-page__feature:nth-child(even) {
  flex-direction: row-reverse;
}

.landing-page__feature:nth-child(even) .landing-page__feature-content {
  order: 2;
}

.landing-page__feature:nth-child(even) .landing-page__feature-image {
  order: 1;
}

.landing-page__feature-image {
  max-width: 500px;
}

.landing-page__feature-content {
  flex: 1;
}

.landing-page__feature-tag,
.landing-page__cta-tag {
  background-color: #d4edda;
  color: #155724;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 10px;
}

.landing-page__feature-title,
.landing-page__cta-title {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.landing-page__feature-description {
  color: #666;
  line-height: 1.6;
}

/* Testimonials Section */
.landing-page__testimonials {
  padding: 60px 20px;
  position: relative;
  background-color: #f9fbfa;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.landing-page__testimonial-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.landing-page__testimonial-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  flex: 1;
  min-width: 300px;
  max-width: 350px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  position: relative;
  border: 1px solid rgba(7, 87, 21, 0.1);
}

.landing-page__testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
}

.landing-page__testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
}

.landing-page__testimonial-avatar-container {
  position: relative;
  width: 70px;
  height: 70px;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f8f1;
  border: 3px solid #075715;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.landing-page__testimonial-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.matt-image {
  filter: contrast(0.95) brightness(1.05) saturate(0.95) blur(0.5px);
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.landing-page__testimonial-user {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.landing-page__testimonial-name {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.landing-page__testimonial-stars {
  display: flex;
  align-items: center;
}

.landing-page__stars-icon {
  width: 85px;
  height: auto;
}

.landing-page__testimonial-text {
  color: #444;
  line-height: 1.6;
  font-size: 1.05em;
  font-style: italic;
  margin-top: auto;
  position: relative;
  padding: 0 10px;
  letter-spacing: 0.01em;
  display: inline-block;
  text-align: left;
}

.landing-page__testimonial-text::before {
  content: "\201C";
  font-size: 2em;
  color: #075715;
  position: absolute;
  left: -8px;
  top: -10px;
  opacity: 0.6;
}

.landing-page__testimonial-text::after {
  content: "\201D";
  font-size: 2em;
  color: #075715;
  position: absolute;
  bottom: -20px;
  right: 0;
  opacity: 0.6;
}

@media only screen and (max-width: 768px) {
  .landing-page__testimonial-cards {
    gap: 25px;
    padding: 0 10px;
  }
  
  .landing-page__testimonial-card {
    min-height: 220px;
    padding: 25px 20px;
  }
  
  .landing-page__testimonial-avatar-container {
    width: 60px;
    height: 60px;
  }
  
  .landing-page__testimonial-name {
    font-size: 1.1em;
  }
  
  .landing-page__testimonial-text {
    font-size: 1em;
  }
}

/* Call to Action Section */
.landing-page__cta {
  position: relative;
  padding: 50px 20px;
  background-color: #f8f9fa;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

.landing-page__cta-content {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.landing-page__cta-input {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.landing-page__cta-image {
  position: absolute;
  right: 0;
  bottom: -10px;
  width: 500px;
}

.landing-page__cta-circle {
  position: absolute;
  left: -100px;
  top: -100px;
  width: 200px;
  height: 200px;
  background-color: #007bff;
  border-radius: 50%;
  opacity: 0.1;
}

/* Footer */
.landing-page__footer-divider {
  height: 1px;
  background-color: #ddd;
  margin: 50px 0;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  /* Features Section Mobile */
  .landing-page__feature {
    flex-direction: column;
    gap: 30px;
    text-align: center;
    padding: 0 15px;
  }

  .landing-page__feature-image {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .landing-page__feature:nth-child(even) .landing-page__feature-content,
  .landing-page__feature:nth-child(even) .landing-page__feature-image {
    order: unset;
  }

  .landing-page__feature-title {
    font-size: 1.5em;
  }

  .landing-page__feature-description {
    font-size: 1rem;
    text-align: left;
  }

  /* CTA Section Mobile */
  .landing-page__cta {
    padding: 40px 20px;
  }

  .landing-page__cta-content {
    padding: 0 15px;
  }

  .landing-page__cta-title {
    font-size: 1.5em;
  }

  .landing-page__cta-image {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 30px auto 0;
    display: block;
    right: unset;
    bottom: unset;
  }

  .landing-page__cta-circle {
    display: none;
  }

  .landing-page__cta-input {
    margin: 20px auto;
    max-width: 300px;
  }

  .landing-page__cta-button {
    display: block;
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .landing-page__logo {
    height: 20vw;
  }

  .landing-page__get-started-button,
  .landing-page__nav {
    display: none;
  }

  .landing-page__header {
    align-self: center;
  }

  .landing-page__hero-container {
    margin-top: 20px;
  }

  .landing-page__feature:nth-child(even) {
    flex-direction: column-reverse;
  }

  .landing-page__action-button {
    font-size: 1rem;
    padding: 15px 25px;
    display: block;
    margin: 0 auto;
    max-width: 280px;
    line-height: 1.2;
    content: "Start charting now";
  }

  .landing-page__cta-image {
    display: none;
  }
}
