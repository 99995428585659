/* Main content area */
.app-main {
  display: flex;
  height: calc(100vh - 70px);
  padding: 20px;
  text-align: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #f0f0f0, #e0e0e0);
}

/* General panel styles */
.left-panel,
.clipboard-container,
.edit-panel {
  flex: 1;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Left panel */
.left-panel {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  scrollbar-width: thin;
  scrollbar-color: var(--medium-green) var(--pale-green);
  width: 250px;
  min-width: 250px;
  max-width: 330px;
  max-height: auto;
  transition: width 0.3s ease, min-width 0.3s ease, max-width 0.3s ease;
  flex-shrink: 0;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--medium-green) var(--off-white);
}

/* Add styles to fix cut-off content */
.list-container {
  overflow-y: auto;
  height: auto;
}

.list-item {
  margin-bottom: 8px; /* Ensure spacing between items */
  overflow: visible; /* Prevent content from being cut off */
}

.content {
  text-align: left;
  overflow-wrap: break-word; /* Ensure text wraps properly */
  word-break: break-word; /* Allow breaking of long words */
}

/* Week grouping styles */
.week-group {
  margin-bottom: 12px;
  border-radius: 6px;
  overflow: auto;
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
}

.week-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #f2f2f2;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
  color: var(--dark-green);
}

.week-header:hover {
  background-color: #e8e8e8;
}

.week-label {
  font-size: 0.9rem;
}

.collapse-icon {
  font-size: 0.8rem;
  transition: transform 0.2s;
}

.week-items {
  max-height: auto;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.week-items.collapsed {
  max-height: 0;
  padding-bottom: 0; /* Remove padding when collapsed */
}

.week-group:last-child {
  margin-bottom: -10px;
}

.left-panel.collapsed {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  margin-left: -20px;
  overflow: hidden;
  padding: 0px;
}

.timestamp {
  flex: 0 0 20%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timestamp .day {
  font-size: 0.9rem;
  color: var(--dark-green);
  font-weight: bold;
}

.timestamp .time {
  font-size: 0.8rem; 
  color: var(--dark-green);
}

/* Left panel animation */
.left-panel-animate {
  animation: slideInFromLeft 0.3s ease-out;
}


.panel-header {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin: 0;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

.left-panel .panel-header {
  background-color: white;
  color: var(--dark-green);
  text-align: center;
  margin-top: -55px;
}

/* Clipboard container */
.clipboard-container {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  margin-left: 0;
  width: 100%;
  transition: margin-left 0.3s ease, flex 0.3s;
  height: calc(100vh - 110px);
  overflow-y: hidden;
}

@media (min-width: 769px) {
  .clipboard-container {
    margin-left: 30px;
  }
}

/* Add animation class for clipboard container that can be added dynamically */
.clipboard-container-animate {
  animation: slideInFromRight 0.3s ease-out;
}

/* Edit Panel Styles */
.edit-panel {
  opacity: 0;
  flex: 0;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: opacity 0.1s, flex 0.3s, width 0.3s;
}

.edit-panel.visible {
  flex: 0.5;
  width: auto;
  opacity: 1;
  margin-left: 30px;
}

.edit-panel-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px 4px 4px 4px;
}

.edit-textarea {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  resize: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  background-color: white;
  transition: border-color 0.2s ease;
}

.edit-textarea::placeholder{
  color: #666666;
}
.edit-textarea:focus {
  outline: none;
  border-color: #c0c0c0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.edit-panel-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 4px;
}

.edit-panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 32px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.edit-panel-button .material-symbols-rounded {
  font-size: 20px;
}

.edit-panel-button.primary {
  background-color: var(--medium-green);
  color: white;
}

.edit-panel-button.primary:hover {
  background-color: var(--dark-green);
}

.edit-panel-button.secondary {
  background-color: transparent;
  border-color: #e0e0e0;
  color: #666;
  padding: 6px;
  width: 32px;
}

.edit-panel-button.secondary:hover {
  background-color: #f5f5f5;
  border-color: #ccc;
}

/* Textarea */
/* Toolbar Styles - Common for both editor and clipboard */
.editor-toolbar,
.clipboard-toolbar {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  gap: 4px;
}

/* Editor toolbar specific styles */
.editor-toolbar {
  justify-content: space-between;
  padding-left: 4px;
}

.clipboard-toolbar {
  justify-content: flex-start;
}

.toolbar-group {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0 4px;
}

.toolbar-button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.toolbar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 6px 12px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 6px;
}

.toolbar-button:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.toolbar-button.primary-button {
  background-color: var(--medium-green);
  color: white;
}

.toolbar-button.primary-button:hover {
  background-color: var(--dark-green);
  border-color: transparent;
}

.toolbar-button.primary-button .material-symbols-rounded,
.toolbar-button.primary-button .toolbar-icon {
  color: white;
}

.toolbar-button.active {
  background-color: #e9ecef;
  border-color: #ced4da;
}

.toolbar-icon {
  font-size: 18px;
}

.toolbar-button:hover .toolbar-icon {
  opacity: 0.8;
}

.material-symbols-rounded.toolbar-icon {
  font-size: 20px;
  width: auto;
  height: auto;
}

.toolbar-button.delete-button {
  color: #666;
}

.toolbar-button.delete-button:hover {
  background-color: #f5f5f5;
  border-color: #ccc;
}

.toolbar-button.active {
  background-color: #e9ecef;
  border-color: #ced4da;
}

/* Copy message tooltip */
.copy-message {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--dark-green);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  animation: fadeIn 0.2s ease-in-out;
}

.copy-message::before {
  content: '';
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid var(--dark-green);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, 5px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* New Note Button Styles */
.new-note-button {
  background: linear-gradient(45deg, var(--medium-green), var(--light-green));
  color: white;
  font-weight: 600;
  padding: 6px 16px;
  min-width: 120px;
}

.new-note-button:hover {
  background: linear-gradient(45deg, var(--dark-green), var(--medium-green));
  border-color: transparent;
}

.plus-icon {
  font-size: 20px;
  font-weight: bold;
  margin-right: 2px;
}

.button-text {
  font-size: 14px;
}

.toolbar-divider {
  width: 1px;
  height: 24px;
  background-color: #dee2e6;
  margin: 0 8px;
}

/* Clipboard and edit panel div */
.clipboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.clipboard-textarea-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.clipboard-textarea {
  width: 96%;
  min-width: 200px; /* Minimum width before horizontal scroll appears */
  height: 66vh;
  padding: 16px;
  margin: 10px 10px 10px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  resize: none;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  background-color: white;
  scrollbar-width: thin;
  scrollbar-color: var(--medium-green) var(--off-white);
}

.clipboard-textarea:focus {
  outline: none;
  border-color: #c0c0c0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.clipboard-textarea::placeholder {
  color: #666666;
  font-family: inherit;
  font-size: 14px;
}

.clipboard-textarea.dictation-active::placeholder {
  color: transparent;
}

.clipboard-textarea.dragging-over {
  border-color: var(--medium-green);
  background-color: rgba(var(--medium-green-rgb), 0.05);
}

/* SOAP Buttons */
.clipboard {
  display: flex;
  position: relative;
  min-width: 0; /* Allows container to shrink below content size */
}

.soap-buttons {
  position: relative;
  width: 30px;
  margin-right: 10px;
  flex-shrink: 0; /* Prevent SOAP buttons from shrinking */
}

.clipboard-content {
  flex: 1;
  min-width: 0; /* Allows textarea to shrink */
  display: flex;
  flex-direction: column;
}

.soap-button {
  position: absolute;
  justify-self: center;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 900;
  color: var(--dark-green);
  opacity: 0.85;
  z-index: 10;
  font-size: 18px;
  padding: 10px;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.soap-button:hover {
  opacity: 1;
  color: var(--dark-green);
  background-color: var(--pale-green);
}

.soap-button:active {
  opacity: 0.7;
}

/* List container */
.list-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 8px;
  padding: 8px 0;
  margin: 0 10px;
  overflow-y: hidden;
}

/* List items */
.list-item {
  background-color: var(--off-white);
  border: 1px solid var(--light-green);
  border-radius: 8px;
  padding: 5px 3px 5px 10px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 14px;
  color: var(--dark-green);
  line-height: 1.4;
  overflow: hidden;
  word-break: break-word;
}

.list-item:hover {
  background-color: var(--pale-green);
  transform: translateY(-2px);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
}

/* New styles for highlighting new items */
.list-item.highlight {
  background-color: var(--pale-green);
  border-color: var(--medium-green);
  box-shadow: 0 0 10px rgba(46, 105, 48, 0.3);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(46, 105, 48, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(46, 105, 48, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(46, 105, 48, 0);
  }
}

/* Placeholder text before first note generated */
.empty-list-message {
  font-size: 1rem;
}

/* Popup container */
.content-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Popup inner content */
.content-popup-inner {
  padding: 30px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80vh;
  width: 100%;
  text-align: left;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--medium-green) var(--pale-green);  
  background-color: var(--off-white);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-header h2 {
  margin: 0;
  color: var(--dark-green);
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.popup-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.popup-close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--dark-green);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.popup-close-button:hover {
  background-color: var(--pale-green);
}

.popup-close-button .material-symbols-rounded {
  font-size: 24px;
}

.popup-menu-container {
  position: relative;
}

.popup-menu-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: none;
}

/* Popup menu */
.popup-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1011;
  border-radius: 4px;
  background-color: var(--off-white);
  border: 1px solid var(--light-green);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup-menu button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: var(--dark-green);
  font-family: 'Roboto', sans-serif;
}

.popup-menu button:hover {
  background-color: var(--pale-green);
}

.content-popup-text {
  font-size: 15px;
  line-height: 1.5;
  color: var(--dark-green);
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
  overflow-x: auto;
  font-family: 'Roboto', sans-serif;
}

/* Styles for mobile view */
@media (max-width: 768px) {
  .content-popup {
    z-index: 1010;
  }
  
  .content-popup-inner {
    max-width: 90%;
    max-height: 85vh;
    width: 90%;
    padding: 20px;
    z-index: 1010;
  }
  
  .popup-header h2 {
    font-size: 1.2rem;
  }
  
  .content-popup-text {
    font-size: 14px;
  }
}

  .content-popup-inner {
    padding: 10px;

  }

/* Mobile Toggle Button - Base Styles */
.mobile-toggle-button {
  position: relative;
  margin-right: -20px;
  top: 10px;
  z-index: 1001;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: left 0.25s ease, right 0.2s ease, background-color 0.2s ease;
  -webkit-tap-highlight-color: transparent;
}

.mobile-toggle-button .material-symbols-rounded:hover {
  transform: scale(1.15);
}

.left-panel.collapsed ~ .mobile-toggle-button {
  transform: translateX(-35px);
  margin-right: -30px;
  transition: transform 0.3s ease, margin-right 0.3s ease;
}

.right-panel.collapsed ~ .mobile-toggle-button {
  transform: translateX(40px);
}

.mobile-toggle-button:hover {
  cursor: pointer;

}

.mobile-toggle-button .material-symbols-rounded {
  font-size: 28px;
  color: #333;
}

.mobile-toggle-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.mobile-toggle-button.disabled .material-symbols-rounded {
  color: #999;
}

.mobile-toggle-button.disabled:hover {
  cursor: not-allowed;
  transform: none;
}

/* For screens with max-width: 768px */
@media (max-width: 768px) {
  .app-main {
    display: flex;
    padding: 0;
    height: calc(100vh - 70px);
    position: relative;
    overflow: hidden;
  }

  .soap-buttons {
    display: none;
  }

  .left-panel {
    position: fixed;
    top: 70px;
    left: 0;
    height: calc(100vh - 70px);
    width: 85%;
    max-width: 85%;
    background-color: white;
    z-index: 1000;
    transition: transform 0.3s ease;
    transform: translateX(0);
    overflow-y: auto;
  }

  .left-panel.collapsed {
    transform: translateX(-100%);
  }



  .left-panel.collapsed + .mobile-content-overlay {
    background-color: transparent;
  }

  .mobile-toggle-button {
    position: relative;
    top: 17px;
    margin-bottom: -20px;
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 1003;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }

  .mobile-toggle-button .material-symbols-rounded {
    font-size: 33px;
    color: var(--dark-green);
    transition: transform 0.3s ease;
  }


  .mobile-toggle-overlay {
    position: fixed;
    top: 70px;
    right: 0;
    width: 0;
    height: calc(100vh - 70px);
    background-color: rgba(128, 128, 128, 0.5);
    z-index: 1002;
    opacity: 0;
    transition: opacity 0.3s ease, width 0.3s ease;
    pointer-events: none;
    display: none;
  }

  /* Add a class for the animation that can be added dynamically */
  .mobile-toggle-overlay-animate {
    animation: fadeInShadow 0.3s ease;
  }

  .clipboard-container {
    margin-left: 0;
    margin-top: -11px;
    width: 100%;
    transition: margin-left 0.3s ease;
    height: calc(100vh - 70px);
    overflow-y: auto;
  }

  .left-panel.collapsed ~ .clipboard-container {
    margin-left: 0;
  }

  .left-panel.collapsed ~ .mobile-toggle-button {
    transform: translateX(10px);
    margin-right: 0px;
    transition: transform 0.3s ease, margin-right 0.3s ease;
  }

  .left-panel:not(.collapsed) ~ .mobile-toggle-button .material-symbols-rounded {
    transition: transform 0.3s ease;
    transform: translateX(86.5vw);
    background-color: white;
    border-radius: 4px;
    padding: 3px;
  }

  .left-panel:not(.collapsed) ~ .mobile-toggle-button:active .material-symbols-rounded:active {
    transform: scale(1.15) translateX(75vw);
  }

  .left-panel:not(.collapsed) ~ .clipboard-container {
    pointer-events: none;
  }

  .left-panel:not(.collapsed) ~ .mobile-toggle-overlay {
    display: block;
    opacity: 1;
    width: 15%;
  }

  .left-panel.collapsed ~ .mobile-toggle-overlay {
    display: none;
    width: 0;
    opacity: 0;
  }

  @keyframes fadeInShadow {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 15%;
    }
  }
  
  @keyframes fadeOutShadow {
    from {
      opacity: 1;
      width: 15%;
    }
    to {
      opacity: 0;
      width: 0;
      display: none;
    }
  }

  .clipboard-textarea-container {
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  .clipboard-textarea {
    transform: translateX(-10px);
    height: 100%;
  }

  .app-main {
    overflow: hidden;
  }
}

/* For screens with min-width: 768px */
@media (min-width: 768px) {
  .app-main {
    flex-direction: row;
  }

  .clipboard-actions {
    display: none;
  }

}

/* This is the styling for the amplify authenticator */
[data-amplify-authenticator] {
  --amplify-components-button-border-radius: 8px;
  --amplify-components-authenticator-modal-background-color: var(--pale-green);
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: var(--medium-green);
  --amplify-components-button-primary-hover-background-color: var(--dark-green);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--light-green);
  --amplify-components-tabs-item-active-border-color: var(--medium-green);
  --amplify-components-tabs-item-color: var(--light-green);
  --amplify-components-tabs-item-active-color: var(--dark-green);
  --amplify-components-button-link-color: var(--medium-green);
  --amplify-components-authenticator-router-border-style: none;
  --amplify-components-authenticator-router-background-color: #f6fdf6;
}

/* Drag and Drop Styles */
.draggable-div {
  cursor: move;
  transition: opacity 0.3s;
}

.draggable-div:active {
  opacity: 0.7;
}

.clipboard-textarea.dragging-over {
  border-color: var(--medium-green);
  background-color: rgba(var(--medium-green-rgb), 0.05);
}

/* Navbar animation */
.navbar {
  animation: fadeInFromTop 0.5s ease-out;
}

/* Keyframe animations for loading effects */
@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeOut 0.5s ease-out 0.5s forwards;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid var(--light-green);
  border-top: 3px solid var(--dark-green);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* Toggle panel styles */
.toggle-container {
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 4px;
  position: relative;
  margin: 8px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-weight: 600;
}

.toggle-option {
  flex: 1;
  padding: 8px 12px;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
  color: #000000;
  border-radius: 6px;
}

.toggle-option.active {
  color: var(--dark-green);
  color: #ffffff;
}

.toggle-option:hover {
  font-weight: 450;
}

.toggle-option .material-symbols-rounded {
  font-size: 16px;
}

.slider {
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: calc(50% - 4px);
  background-color: var(--medium-green);
  border-radius: 6px;
  transition: transform 0.3s ease;
  z-index: 1;
}

.toggle-panel {
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Center div styles */
.right-align-div {
  display: flex;
  justify-content: right;
  align-items: center;
  z-index: 5;
}

.fade-content {
  transition: opacity 0.3s ease;
  opacity: 1;
}

.left-panel.collapsed .fade-content {
  opacity: 0;
}

/* Left panel children animation similar to edit panel */
.left-panel .fade-content > * {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.1s, transform 0.1s;
}

.left-panel:not(.collapsed) .fade-content > * {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.1s;
}

.left-panel.collapsed .fade-content > * {
  opacity: 0;
  transform: translateY(20px);
  transition-delay: 0s;
}

/* Adjust left panel fade-content children animation for staggered delays */
.left-panel .fade-content > * {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Expanded state: staggered delays for each child */
.left-panel:not(.collapsed) .fade-content > *:nth-child(1) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.1s;
}
.left-panel:not(.collapsed) .fade-content > *:nth-child(2) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
}
.left-panel:not(.collapsed) .fade-content > *:nth-child(3) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
}

/* Collapsed state: reverse order staggered delays */
.left-panel.collapsed .fade-content > *:nth-child(1) {
  transition-delay: 0.3s;
}
.left-panel.collapsed .fade-content > *:nth-child(2) {
  transition-delay: 0.2s;
}
.left-panel.collapsed .fade-content > *:nth-child(3) {
  transition-delay: 0.1s;
}

/* When left panel is collapsed, center the contents of .right-align-div */
.left-panel.collapsed .right-align-div {
  justify-content: center;
}

.clear-clipboard-link {
  display: none;
}

/* Added appearance animation for mobile-toggle-overlay */
@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.mobile-toggle-overlay {
  animation: fadeInFromLeft 0.1s ease;
}

/* Disable pointer events during recording/processing */
.app.processing-active {
  position: relative;
}

.app.processing-active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; /* Just below the recording popup z-index (1000) */
  pointer-events: none;
}

.app.processing-active .create-note-popup {
  z-index: 1000;
  pointer-events: auto;
}

.app.processing-active > *:not(.create-note-popup) {
  pointer-events: none;
}
